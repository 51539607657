<template>
  <validation-observer #default="{handleSubmit}">
    <b-form @submit.prevent>
      <fieldset :disabled="$attrs.disabled">
        <b-form-row>
          <!-- Username -->
          <b-col md="4">
            <text-input
              v-bind="{
                id:'username',
                rules:'required|max:150',
                name:'Username',
              }"
              :text.sync="tempOrganizer.name"
            />
          </b-col>
          <!-- First Name -->
          <b-col md="4">
            <text-input
              v-bind="{
                id:'first-name',
                rules:'required|max:150',
                name:'First Name',
              }"
              :text.sync="tempOrganizer.first_name"
            />
          </b-col>
          <!-- Last Name -->
          <b-col md="4">
            <text-input
              v-bind="{
                id:'last-name',
                rules:'required|max:150',
                name:'Last Name',
              }"
              :text.sync="tempOrganizer.last_name"
            />
          </b-col>
          <!-- Phone -->
          <b-col md="4">
            <text-input
              v-bind="{
                id:'phone',
                rules:'required|max:20|phone',
                name:'Phone',
              }"
              :text.sync="tempOrganizer.phone_number"
            />
          </b-col>
          <!-- Email -->
          <b-col md="4">
            <text-input
              v-bind="{
                id:'email',
                rules:'required|email',
                name:'Email',
              }"
              :text.sync="tempOrganizer.email"
            />
          </b-col>
          <!-- About -->
          <b-col
            cols="12"
            class="mb-2"
          >
            <text-area-input
              v-bind="{
                id:'about',
                rules:'max:250',
                name:'About',
              }"
              :text.sync="tempOrganizer.about"
            />
          </b-col>
          <!-- Location -->
          <location-inputs
            :details="tempOrganizer.location"
            class="mb-2"
          />
          <!-- Facebook Link -->
          <b-col
            md="6"
          >
            <text-input
              v-bind="{
                id:'facebook-link',
                rules:'max:500|url|facebook-url',
                name:'Facebook Link',
              }"
              :text.sync="tempOrganizer.social_facebook"
            />
          </b-col>
          <!-- Youtube Link -->
          <b-col
            md="6"
          >
            <text-input
              v-bind="{
                id:'youtube-link',
                rules:'max:500|url|youtube-channel',
                name:'Youtube Link',
              }"
              :text.sync="tempOrganizer.social_youtube"
            />
          </b-col>
          <b-col md="6">
            <b-form-row>
              <b-col cols="12">
                <upload-image
                  id="organizer-logo"
                  label="Logo"
                  :images.sync="tempOrganizer.logo"
                />
              </b-col>
              <b-col cols="12">
                <images-base-64-preview
                  file-id="organizer-logo"
                  :images.sync="tempOrganizer.logo"
                />
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>
      </fieldset>

      <b-form-row v-if="$attrs.disabled === undefined">
        <back />
        <submit-button
          v-bind="{submit,handleSubmit}"
          class="ml-auto mt-2"
        />
      </b-form-row>
    </b-form>
  </validation-observer>
</template>
<script>
import Back from '../common/FormInputs/Back.vue'
import ImagesBase64Preview from '../common/FormInputs/ImagesBase64Preview.vue'
import LocationInputs from '../common/FormInputs/LocationInputs.vue'
import SubmitButton from '../common/FormInputs/SubmitButton.vue'
import TextAreaInput from '../common/FormInputs/TextAreaInput.vue'
import TextInput from '../common/FormInputs/TextInput.vue'
import UploadImage from '../common/FormInputs/UploadImage.vue'

export default {
  name: 'EventOrganizerForm',
  components: {
    SubmitButton,
    TextInput,
    LocationInputs,
    ImagesBase64Preview,
    UploadImage,
    TextAreaInput,
    Back,
  },
  props: {
    organizer: { type: Object, default: () => {} },
    submit: { type: Function, default: () => {} },
  },
  computed: {
    tempOrganizer: {
      get() {
        return this.organizer
      },
      set(value) {
        this.$emit('update:organizer', value)
      },
    },
  },
}
</script>
<style lang="scss">

</style>
