<template>
  <validation-provider
    #default="validationContext"
    :name="name"
    :rules="rules"
  >
    <b-form-group
      :label-for="id"
    >
      <template #label>
        <span
          :class="{required:JSON.stringify(rules).includes('required')}"
        >
          {{ name }}
        </span>

      </template>
      <b-form-textarea
        :id="id"
        v-model="tempText"
        :class="$attrs['input-class']"
        style="padding:20px !important"
        :placeholder="placeholder ? placeholder : name"
        :state="validate(validationContext)"
        :no-resize="!resizable"
      />
      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>

      <div class="text-right">
        <span class="text-primary">{{ tempText?tempText.length: 0 }} chars</span>
      </div>
    </b-form-group>
  </validation-provider>
</template>
<script>

export default {
  name: 'TextInput',
  props: {
    name: { type: String, default: '' },
    id: { type: String, default: '' },
    text: { type: String, default: '' },
    rules: { type: String, default: '' },
    resizable: { type: Boolean, default: true },
    placeholder: { type: String, default: '' },
  },
  computed: {
    tempText: {
      get() {
        return this.text
      },
      set(value) {
        this.$emit('update:text', value)
      },
    },
  },
  methods: {
    validate(validationContext) {
      return validationContext.dirty || validationContext.validated ? validationContext.valid : null
    },
  },
}
</script>
<style lang="">

</style>
