import { ref } from '@vue/composition-api'
import Vue from 'vue'

export default function locationAutoComplete() {
  let autoComplete = null
  const locationRef = ref(null)

  const resetLocation = organizationData => {
    Vue.set(organizationData, 'street', null)
    Vue.set(organizationData, 'postal_code', null)
    Vue.set(organizationData, 'state', null)
    Vue.set(organizationData, 'city', null)
    Vue.set(organizationData, 'country', null)
    Vue.set(organizationData, 'lat', null)
    Vue.set(organizationData, 'lng', null)
  }

  const fillLocationDetails = (organizationData, locationPoint) => {
    resetLocation(organizationData)
    const place = autoComplete.getPlace()
    if (locationPoint.coordinates) {
      locationPoint.coordinates = [place.geometry.location.lat(), place.geometry.location.lng()]
    } else {
      locationPoint.lat = place.geometry.location.lat()
      locationPoint.lng = place.geometry.location.lng()
    }

    const streetNumber = place.address_components.find(addressComponent => addressComponent.types[0] === 'street_number')?.short_name
    const unitNumber = place.address_components.find(addressComponent => addressComponent.types[0] === 'subpremise')?.short_name
    place.address_components.forEach(component => {
      const componentType = component.types[0]

      switch (componentType) {
        case 'route':
          Vue.set(organizationData, 'street',
            `${streetNumber ?? ''} ${component.short_name} ${unitNumber ?? ''}`)
          break
        case 'postal_code_prefix':
        case 'postal_code':
          Vue.set(organizationData, 'postal_code', component.long_name)
          break
        case 'administrative_area_level_1':
          Vue.set(organizationData, 'state', component.short_name)
          break
        case 'locality':
          Vue.set(organizationData, 'city', component.long_name)
          break
        case 'country':
          Vue.set(organizationData, 'country', component.short_name)
          break
        default:
          break
      }
    })
  }

  const initAutocomplete = (organizationData, locationPoint = organizationData) => {
    // eslint-disable-next-line no-undef
    autoComplete = new google.maps.places.Autocomplete(locationRef.value, {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components', 'geometry'],
      types: ['address'],
    })
    autoComplete.addListener('place_changed', () => { fillLocationDetails(organizationData, locationPoint) })
  }

  return {
    initAutocomplete,
    locationRef,
  }
}
