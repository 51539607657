<template>
  <div>
    <b-form-row>
      <!-- Location -->
      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="Location"
        >
          <b-form-group
            label="Address"
            label-for="location"
          >
            <input
              id="location"
              ref="locationRef"
              v-b-tooltip.hover
              title="This input is only for searching address"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Enter address to search"
              class="form-control"
            >
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Street -->
      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="street"
          :rules="rules"
        >
          <b-form-group
            label-for="street"
          >
            <template #label>
              Street
              <span
                :class="{required:JSON.stringify(rules).includes('required')}"
              />
            </template>
            <b-form-input
              id="street"
              v-model="details.street"
              :state="getValidationState(validationContext)"
              placeholder="Street"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- City -->
      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="city"
          :rules="rules"
        >
          <b-form-group
            label-for="city"
          >
            <template #label>
              City
              <span
                :class="{required:JSON.stringify(rules).includes('required')}"
              />
            </template>
            <b-form-input
              id="city"
              v-model="details.city"
              :state="getValidationState(validationContext)"
              trim
              placeholder="City"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="state"
          :rules="rules"
        >
          <b-form-group
            label-for="state"
          >
            <template #label>
              State
              <span
                :class="{required:JSON.stringify(rules).includes('required')}"
              />
            </template>
            <b-form-input
              id="state"
              v-model="details.state"
              :state="getValidationState(validationContext)"
              trim
              placeholder="State"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="country"
          :rules="rules"
        >
          <b-form-group
            label-for="country"
          >
            <template #label>
              Country
              <span
                :class="{required:JSON.stringify(rules).includes('required')}"
              />
            </template>
            <b-form-input
              id="country"
              v-model="details.country"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Country"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Postal Code -->
      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="Postal Code"
        >
          <b-form-group
            label-for="postalCode"
          >
            <template #label>
              Zip / Postal Code
              <span
                :class="{required:JSON.stringify(rules).includes('required')}"
              />
            </template>
            <b-form-input
              id="postalCode"
              v-model="details.postal_code"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Zip / Postal Code"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="latitude"
          :rules="rules"
        >
          <b-form-group
            label-for="latitude"
          >
            <template #label>
              Latitude
              <span
                :class="{required:JSON.stringify(rules).includes('required')}"
              />
            </template>
            <b-form-input
              id="latitude"
              v-model="details.lat"
              :state="getValidationState(validationContext)"
              trim
              type="number"
              step="any"
              placeholder="Latitude"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col :md="inputColumns">
        <validation-provider
          #default="validationContext"
          name="longitude"
          :rules="rules"
        >
          <b-form-group
            label-for="longitude"
          >
            <template #label>
              Longitude
              <span
                :class="{required:JSON.stringify(rules).includes('required')}"
              />
            </template>
            <b-form-input
              id="longitude"
              v-model="details.lng"
              :state="getValidationState(validationContext)"
              trim
              type="number"
              step="any"
              placeholder="Longitude"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import locationAutoComplete from '@/common/compositions/common/locationAutoComplete'

export default {
  name: 'LocationInputs',
  props: {
    details: { type: Object, default: () => {} },
    inputColumns: { type: Number, default: 3 },
    rules: { type: String, default: '' },
  },
  mounted() {
    this.initAutocomplete(this.details)
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    const {
      initAutocomplete,
      locationRef,
    } = locationAutoComplete()
    return {
      initAutocomplete,
      locationRef,
      getValidationState,
    }
  },
}
</script>
<style lang="scss">

</style>
